// Source: https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
// Source: https://csdcorp.com/blog/coding/oauth2-get-a-token-via-rest-google-sign-in/

import * as React from "react";
import { View, SafeAreaView, ScrollView } from "react-native";
import Fire from "../../utils/Firebase.js";
import styles from "../../utils/styles.js";

import GoogleButton from "../../elements/GoogleButton.js";
import ForageTitle from "../../elements/ForageTitle.js";

const AnonymousUser = require("../../assets/AnonymousUser.png");

// const androidGoogleServices = require('../../utils/android/google-services.json');

const GoogleAuthURL = `https://accounts.google.com/o/oauth2/auth`;
const response_type = `code`;
const scope = `profile`;
const redirect_uri = `https://www.forageapp.net/`;
// const client_id = androidGoogleServices.client[0].oauth_client[0].client_id;

export default class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.initNewUser = this.initNewUser.bind(this);
    this.googleSignin = this.googleSignin.bind(this);
  }

  initNewUser = async () => {
    const isUserNew = await Fire.shared.isNewUser();
    if (isUserNew) {
      await Fire.shared.setupNewUser();
    }
  };

  googleSignin = async () => {
    console.log("Google Button Pressed.");
    if (Fire.shared.auth.currentUser != null) {
      console.log("User Logged in!!!");
      this.props.navigation.navigate("AccountScreen");
    } else {
      Fire.shared.google_signin_with_popup().then(() => {
        if (Fire.shared.auth.currentUser != null) {
          console.log("User logged in through google.");
          this.initNewUser().then(() => {
            this.props.navigation.navigate("AccountScreen");
          })
        } else {
          console.log("User NOT Logged in through google!!!");
        }
      });
    }
  };

  render() {
    return (
      <SafeAreaView style={styles.SafeAreaViewStyle}>
        <ScrollView
          style={{ flex: 1, marginHorizontal: 10 }}
          contentContainerStyle={{ flex: 1, justifyContent: "center" }}
        >
          <View style={styles.ViewStyle}>
            <View>
              <ForageTitle />
            </View>
            <View style={{ padding: 10 }} />
            <GoogleButton onPress={this.googleSignin} />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
