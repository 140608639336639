import * as React from "react";
import { View, Text, SafeAreaView, ScrollView, FlatList } from "react-native";

import styles from "../../utils/styles.js";
import styleConst from "../../utils/styleConst.js";
import Fire from "../../utils/Firebase.js";
import ForageTitle from "../../elements/ForageTitle.js";

import ForageButton from "../../elements/ForageButton.js";
import ForageAlert from "../../elements/ForageAlert.js";
import ForageSwitch from "../../elements/ForageSwitch.js";

export default class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSettings: {},
    };
    this.deleteAccount = this.deleteAccount.bind(this);
    this.getUserSettings = this.getUserSettings.bind(this);
    this.renderUserSettings = this.renderUserSettings.bind(this);
  }

  componentDidMount() {
    this.getUserSettings();
  }

  deleteAccount = async () => {
    console.log("Deleting user account...");
    Fire.shared
      .userDeleteAccount()
      .then(() => {
        this.props.navigation.navigate("Login");
      })
      .catch((err) => {
        ForageAlert(
          (props = {
            title: "Error Deleting Account",
            message:
              "Attempt to log back in and deleting your account at a later time.",
          })
        );
        this.props.navigation.navigate("Login");
      });
  };

  getUserSettings = async () => {
    Fire.shared
      .getUserSettings()
      .then((userSettingsData) => {
        this.setState({ userSettings: userSettingsData });
      })
      .catch((err) =>
        ForageAlert(
          (props = {
            title: "Error getting user settings",
            message: "Try reloading user settings at a later time.",
          })
        )
      );
  };

  renderUserSettings = (settingItem) => {
    if (typeof settingItem.value == "boolean") {
      return (
        <View style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ForageSwitch
            icon={settingItem.icon}
            title={settingItem.name}
            beta={settingItem.isBeta}
            disabled={false}
            value={settingItem.value}
            onValueChange={async () => {
              let tmpSettings = { ...this.state.userSettings };
              for (const key in tmpSettings) {
                if (tmpSettings[key].name == settingItem.name) {
                  tmpSettings[key].value = !settingItem.value;
                }
              }
              await Fire.shared.setUserSettings(tmpSettings);
              await this.getUserSettings();
            }}
          />
        </View>
      );
    }
    return <View />;
  };

  render() {
    return (
      <SafeAreaView style={styles.SafeAreaViewStyle}>
        <ScrollView
          style={styles.ScrollViewStyle}
          contentContainerStyle={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View>
            <ForageTitle />
          </View>

          <Text style={[{ padding: 10 }, styles.forageHeaderText]}>
            Settings
          </Text>

          <FlatList
            data={Object.values(this.state.userSettings).sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            renderItem={({ item }) => this.renderUserSettings(item)}
            numColumns={1}
            onRefresh={() => {
              this.getUserSettings();
            }}
            refreshing={true}
          />

          <View style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ForageButton
              title="Reset to default settings"
              onPress={async () => {
                await Fire.shared.setUserSettings();
                await this.getUserSettings();
              }}
            />
          </View>

          <View style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ForageButton
              color={styleConst.foragePurple}
              fontColor="white"
              title="Delete Account"
              onPress={this.deleteAccount}
            />
          </View>
          {/* </View> */}
        </ScrollView>
      </SafeAreaView>
    );
  }
}
