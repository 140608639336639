
/*
Forage Database names.
*/ 

const databaseNames = {
    // Database Names.
    test: "(default)",
    prod: "production",

    // Database collection names.
    collections: {
        blacklist: "blacklist",
        promos: "promos",
        restaurants: "restaurants",
        websitePwd: "websitePwd",
        userSettings: "userSettings",
    },
}

export default databaseNames;
