import * as React from "react";
import { Component } from "react";
import {
  Text,
  View,
  FlatList,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Linking,
  Image,
} from "react-native";

import styles from "../../utils/styles.js";
import ForageTitle from "../../elements/ForageTitle";
import RestaurantTile from "../../elements/RestaurantTile.js";
import styleConst from "../../utils/styleConst.js";

function HomepageListTile(title, listData) {
  return (
    <View style={styles.HomepageTileView}>
      <Text style={styles.HomepageHeading}>{title} </Text>
      <FlatList
        data={listData}
        renderItem={({ item }) => {
          return (
            <View style={{ marginBottom: 10 }}>
              {/* <Text style={{ fontSize: 20, textAlign: 'center' }}>{`\u2022 ${item.key}`}</Text> */}
              <Text
                style={styles.forageSubtextBullets}
              >{`\u2022 ${item.key}`}</Text>
            </View>
          );
        }}
      />
    </View>
  );
}

export default class HomeScreen extends Component {
  render() {
    return (
      <SafeAreaView style={styles.SafeAreaViewStyle}>
        <ScrollView
          // style={styles.ScrollViewStyle} // Adds horizontal margin of 10
          // style={{flex: 1}}
          contentContainerStyle={{
            justifyContent: "center",
            alignContent: "stretch",
          }}
        >
          {/* Top Elements */}

          {/* Forage Title */}
          <View style={{ padding: 10 }}>
            <ForageTitle />
          </View>

          {/* Header Description */}
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Text style={styles.forageSubtextPlain}>
              Explore restaurant delivery and pickup options.
            </Text>
            <Text style={styles.forageSubtextPlain}>
              Discover the best deals!
            </Text>
            <Text style={styles.forageSubtextPlain}>Skip the guesswork.</Text>
          </View>

          {/* Problem & Solution Cards  */}
          <View
            style={[
              styles.HomepageRowView,
              { backgroundColor: styleConst.foragePurple },
            ]}
          >
            {HomepageListTile("🤔 Problem", [
              {
                key: "Excessive amount of time to checking food delivery service.",
              },
              {
                key: "Significant variance on costs/fee/price on identical orders.",
              },
              {
                key: "Unknown degree of price variations between services.",
              },
              {
                key: "Underutilized promotional codes.",
              },
            ])}

            {HomepageListTile("✅ Solution", [
              {
                key: "Forage guarantees savings average of 25%-50% on identical orders.",
              },
              {
                key: "Use Forage’s platform to search for food and get an estimates.",
              },
              {
                key: "Forage displays which promotions are being offered.",
              },
              {
                key: "Forage displays price breakdown to save you time and money.",
              },
            ])}
          </View>

          <View
            style={[
              styles.HomepageColView,
              { backgroundColor: styleConst.forageGreen },
            ]}
          >
            <View style={{ flexGrow: 1 }}>
              {HomepageListTile("🏷️ Compare Prices", [
                {
                  key: "View fee comparison estimates from multiple services.",
                },
                {
                  key: "Use builtin cart estimate calculator to decide how much to spend.",
                },
                {
                  key: "One stop comparison shop!",
                },
              ])}
            </View>
            <View style={styles.RestaurantTileListView}>
              <RestaurantTile
                restaurantInfo={{
                  name: "Example Restaurant",
                  formatted_address: "1234 Address Ln., Baltimore",
                  rating: 5,
                  price_level: 2,
                }}
                services={[
                  {
                    service_name: "Postmates",
                    delivery_fee: "$1.99",
                    service_fee: "$0.50",
                    delivery_time: "30",
                  },
                  {
                    service_name: "Grubhub",
                    delivery_fee: "$2.99",
                    service_fee: "$0.75",
                    delivery_time: "45",
                  },
                ]}
              />
            </View>
          </View>

          <View
            style={[
              styles.HomepageRowView,
              { backgroundColor: styleConst.foragePurple },
            ]}
          >
            {HomepageListTile("🎫 Share Promos", [
              {
                key: "View currently active promotions.",
              },
              {
                key: "Reduce promotional email clutter.",
              },
              {
                key: "Share currently active promotional codes with a community",
              },
            ])}
          </View>
        </ScrollView>

        {/* Bottom Elements */}
        {/* Privacy, security, and other information */}
        <View style={styles.PolicyView}>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate("PrivacyScreen");
            }}
          >
            <Text style={styles.PolicyText}>Privacy Policy</Text>
          </TouchableOpacity>

          <Text> | </Text>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL("mailto:support@forageapp.net");
            }}
          >
            <Text style={styles.PolicyText}>Contact</Text>
          </TouchableOpacity>

          <Text> | </Text>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL("https://forms.gle/Mx1PEwXNoigVMK6N7");
            }}
          >
            <Text style={styles.PolicyText}>Feedback</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}
