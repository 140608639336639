import React from "react";
import { View, TouchableOpacity, Text, Alert, Platform } from "react-native";
import { MaterialCommunityIcons } from "react-native-vector-icons";
import styles from "../utils/styles";
import styleConst from "../utils/styleConst";

// https://stackoverflow.com/questions/65481226/react-native-alert-alert-only-works-on-ios-and-android-not-web

const createOneButtonAlert = (props) =>
  Alert.alert(
    props.title ? props.title : "Alert",
    props.message ? props.message : "",
    [
      {
        text: "Cancel",
        onPress: () => Alert.alert("Cancel Pressed"),
        style: "cancel",
      },
    ],
    {
      cancelable: true,
      onDismiss: () =>
        Alert.alert(
          "This alert was dismissed by tapping outside of the alert dialog."
        ),
    }
  );

const windowConfirm = (props) => {
  const title = props.title;
  const message = props.message;
  const options = props.options;
  const buttons = props.buttons;
  const result = window.confirm(
    [title, message].filter(Boolean).join("\n")
  );

  // if (result) {
  //   const confirmOption = options.find(({ style }) => style !== "cancel");
  //   confirmOption && confirmOption.onPress();
  // } else {
  //   const cancelOption = options.find(({ style }) => style === "cancel");
  //   cancelOption && cancelOption.onPress();
  // }
};

const createTwoButtonAlert = () =>
  Alert.alert("Alert Title", "My Alert Msg", [
    {
      text: "Cancel",
      onPress: () => console.log("Cancel Pressed"),
      style: "cancel",
    },
    { text: "OK", onPress: () => console.log("OK Pressed") },
  ]);

const createThreeButtonAlert = () =>
  Alert.alert("Alert Title", "My Alert Msg", [
    {
      text: "Ask me later",
      onPress: () => console.log("Ask me later pressed"),
    },
    {
      text: "Cancel",
      onPress: () => console.log("Cancel Pressed"),
      style: "cancel",
    },
    { text: "OK", onPress: () => console.log("OK Pressed") },
  ]);

const ForageAlert = (props) => {
  if (Platform.OS === 'web') {
    windowConfirm(props)
  } else {
    createOneButtonAlert(props);
  }
};

export default ForageAlert;
