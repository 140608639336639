// CustomText.js
import React from "react";
import { Text, StyleSheet, Linking } from "react-native";

export default function ForageLinkText(props) {
  return (
    <Text
      style={[props.style, { color: "blue" }]}
      onPress={() => {
        props.link ? Linking.openURL(props.link) : console.log("text pressed.");
      }}
    >
      {props.children}
    </Text>
  );
}
