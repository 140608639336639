import * as React from "react";
import { Component } from "react";
import {
  Text,
  View,
  Linking,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  FlatList,
} from "react-native";

import styles from "../../utils/styles.js";
import ForageTitle from "../../elements/ForageTitle.js";
import ForageLinkText from "../../elements/ForageLinkText.js";

// BulletPoints = ({ item }) => {
//   return (
//     <View>
//       {item.key}
//     </View>
//   );
// }

export default class PrivacyPolicyScreen extends Component {
  render() {
    return (
      <ScrollView style={styles.ScrollViewStyle}>
        <View>
          <ForageTitle />
        </View>
        <Text style={styles.forageSubtextPlain}>Privacy Policy</Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          This Privacy Notice for Forage App, LLC (“we,” “us,” or “our”),
          describes how and why we might access, collect, store, use, and/or
          share ("process") your personal information when you use our services
          ("Services"), including when you:
          <br />· Visit our website at{" "}
          <ForageLinkText link={"https://forageapp.net/"}>
            ForageApp
          </ForageLinkText>
          , or any website of ours that links to this Privacy Notice
          <br />· Download and use our Facebook application, or any other
          application of ours that links to this Privacy Notice
          <br />· Engage with us in other related ways, including any sales,
          marketing, or events
          <br />
          <br />
          <Text style={{ fontWeight: "bold" }}>
            Questions or concerns?
          </Text>{" "}
          Reading this Privacy Notice will help you understand your privacy
          rights and choices. We are responsible for making decisions about how
          your personal information is processed. If you do not agree with our
          policies and practices, please do not use our Services. If you still
          have any questions or concerns, please contact us at
          <ForageLinkText link={"mailto:support@forageapp.net"}>
            {" "}
            Support@forageapp.net
          </ForageLinkText>
          .
        </Text>
        <br />
        <Text style={styles.foragePrivacyPolicyHeader1}>
          SUMMARY OF KEY POINTS
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          This summary provides key points from our Privacy Notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. What personal information do we
          process? When you visit, use, or navigate our Services, we may process
          personal information depending on how you interact with us and the
          Services, the choices you make, and the products and features you use.
          Learn more about personal information you disclose to us. Do we
          process any sensitive personal information? Some of the information
          may be considered "special" or "sensitive" in certain jurisdictions,
          for example your racial or ethnic origins, sexual orientation, and
          religious beliefs. We do not process sensitive personal information.
          Do we collect any information from third parties? We may collect
          information from public databases, marketing partners, social media
          platforms, and other outside sources. Learn more about information
          collected from other sources. How do we process your information? We
          process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about how we process
          your information.
        </Text>
        <br />
        <Text style={styles.foragePrivacyPolicyHeader1}>
          In what situations and with which parties do we share personal
          information?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may share information in specific situations and with specific
          third parties. Learn more about when and with whom we share your
          personal information. How do we keep your information safe? We have
          adequate organizational and technical processes and procedures in
          place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about how we keep your
          information safe. What are your rights? Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more about
          your privacy rights. How do you exercise your rights? The easiest way
          to exercise your rights is by submitting a data subject access
          request, or by contacting us. We will consider and act upon any
          request in accordance with applicable data protection laws. Want to
          learn more about what we do with any information we collect? Review
          the Privacy Notice in full.
        </Text>
        <br />
        <Text style={styles.foragePrivacyPolicyHeader1}>TABLE OF CONTENTS</Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          <br /> 1. WHAT INFORMATION DO WE COLLECT?
          <br /> 2. HOW DO WE PROCESS YOUR INFORMATION?
          <br /> 3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          <br /> 4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
          <br /> 5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          <br /> 6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
          <br /> 7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          <br /> 8. HOW LONG DO WE KEEP YOUR INFORMATION?
          <br /> 9. HOW DO WE KEEP YOUR INFORMATION SAFE?
          <br /> 10. DO WE COLLECT INFORMATION FROM MINORS?
          <br /> 11. WHAT ARE YOUR PRIVACY RIGHTS?
          <br /> 12. CONTROLS FOR DO-NOT-TRACK FEATURES
          <br /> 13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          <br /> 14. DO WE MAKE UPDATES TO THIS NOTICE?
          <br /> 15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          <br /> 16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
          FROM YOU?
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          1. WHAT INFORMATION DO WE COLLECT?
        </Text>
        <br />{" "}
        <Text style={{ fontWeight: "bold" }}>
          Personal information you disclose to us
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We collect personal information that you provide to us. We
          collect personal information that you voluntarily provide to us when
          you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
          <br />
          <br />
          <Text style={{ fontWeight: "bold" }}>
            Personal Information Provided by You.
          </Text>{" "}
          <br />
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
          <br />· names
          <br />· phone numbers
          <br />· email addresses
          <br />· usernames
          <br />· passwords
          <br />
          <br />
          <Text style={{ fontWeight: "bold" }}>Sensitive Information.</Text>
          <br />
          We do not process sensitive information.
          <br />
          <br />
          <Text style={{ fontWeight: "bold" }}>Social Media Login Data.</Text>
          <br />
          We may provide you with the option to register with us using your
          existing social media account details, like your Facebook, X, or other
          social media account. If you choose to register in this way, we will
          collect certain profile information about you from the social media
          provider, as described in the section called "HOW DO WE HANDLE YOUR
          SOCIAL LOGINS?" below. All personal information that you provide to us
          must be true, complete, and accurate, and you must notify us of any
          changes to such personal information.
          <br />
          <br />
          <Text style={{ fontWeight: "bold" }}>
            Information automatically collected.{" "}
          </Text>
          <br />
          In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Services.
          <br />
          <br />
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
          <br />
          <br />
          Like many businesses, we also collect information through cookies and
          similar technologies.
          <br />
          <br />
          The information we collect includes:
          <br />· Log and Usage Data. Log and usage data is service-related,
          diagnostic, usage, and performance information our servers
          automatically collect when you access or use our Services and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type,
          and settings and information about your activity in the Services (such
          as the date/time stamps associated with your usage, pages and files
          viewed, searches, and other actions you take such as which features
          you use), device event information (such as system activity, error
          reports (sometimes called "crash dumps"), and hardware settings).
          <br />· Device Data. We collect device data such as information about
          your computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
          <br />· Location Data. We collect location data such as information
          about your device's location, which can be either precise or
          imprecise. How much information we collect depends on the type and
          settings of the device you use to access the Services. For example, we
          may use GPS and other technologies to collect geolocation data that
          tells us your current location (based on your IP address). You can opt
          out of allowing us to collect this information either by refusing
          access to the information or by disabling your Location setting on
          your device. However, if you choose to opt out, you may not be able to
          use certain aspects of the Services.
          <br />· Delivery Service Data. Delivery Service Data is information
          about which provider the user's visit after searching the site.
          <br />
          <br />
          <Text style={{ fontWeight: "bold" }}>Google API</Text>
          <br />
          Our use of information received from Google APIs will adhere to Google
          API Services User Data Policy, including the Limited Use requirements.
          <br />
          Information collected from other sources In Short: We may collect
          limited data from public databases, marketing partners, social media
          platforms, and other outside sources. In order to enhance our ability
          to provide relevant marketing, offers, and services to you and update
          our records, we may obtain information about you from other sources,
          such as public databases, joint marketing partners, affiliate
          programs, data providers, social media platforms, and from other third
          parties. This information includes mailing addresses, job titles,
          email addresses, phone numbers, intent data (or user behavior data),
          Internet Protocol (IP) addresses, social media profiles, social media
          URLs, and custom profiles, for purposes of targeted advertising and
          event promotion. If you interact with us on a social media platform
          using your social media account (e.g., Facebook or X), we receive
          personal information about you from such platforms such as your name,
          email address, and gender. You may have the right to withdraw your
          consent to processing your personal information. Learn more about
          withdrawing your consent. Any personal information that we collect
          from your social media account depends on your social media account's
          privacy settings. Please note that their own use of your information
          is not governed by this Privacy Notice. Information collected when you
          use our Facebook application(s). We by default access your Facebook
          basic account information, including your name, email, gender,
          birthday, current city, and profile picture URL, as well as other
          information that you choose to make public. We may also request access
          to other permissions related to your account, such as friends,
          check-ins, and likes, and you may choose to grant or deny us access to
          each individual permission. For more information regarding Facebook
          permissions, refer to the Facebook Permissions Reference page.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To facilitate account creation and authentication and otherwise
          manage user accounts. We may process your information so you can
          create and log in to your account, as well as keep your account in
          working order.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To deliver and facilitate delivery of services to the user. We may
          process your information to provide you with the requested service.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To send administrative information to you. We may process your
          information to send you details about our products and services,
          changes to our terms and policies, and other similar information.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To request feedback. We may process your information when necessary
          to request feedback and to contact you about your use of our Services.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To send you marketing and promotional communications. We may process
          the personal information you send to us for our marketing purposes, if
          this is in accordance with your marketing preferences. You can opt out
          of our marketing emails at any time. For more information, see "WHAT
          ARE YOUR PRIVACY RIGHTS?" below.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To deliver targeted advertising to you. We may process your
          information to develop and display personalized content and
          advertising tailored to your interests, location, and more.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To protect our Services. We may process your information as part of
          our efforts to keep our Services safe and secure, including fraud
          monitoring and prevention.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To evaluate and improve our Services, products, marketing, and your
          experience. We may process your information when we believe it is
          necessary to identify usage trends, determine the effectiveness of our
          promotional campaigns, and to evaluate and improve our Services,
          products, marketing, and your experience.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To identify usage trends. We may process information about how you
          use our Services to better understand how they are being used so we
          can improve them.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To determine the effectiveness of our marketing and promotional
          campaigns. We may process your information to better understand how to
          provide marketing and promotional campaigns that are most relevant to
          you.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To comply with our legal obligations. We may process your
          information to comply with our legal obligations, respond to legal
          requests, and exercise, establish, or defend our legal rights.
          Increase the data's accuracy.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · To help users find the best deal for them.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Vendors, Consultants, and Other Third-Party Service Providers. We may
          share your data with third-party vendors, service providers,
          contractors, or agents ("third parties") who perform services for us
          or on our behalf and require access to such information to do that
          work.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          The third parties we may share personal information with are as
          follows:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Advertising, Direct Marketing, and Lead Generation
          <br />
          Google AdSense
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Allow Users to Connect to Their Third-Party Accounts Google account
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Functionality and Infrastructure Optimization Firebase Hosting ,
          Firebase Realtime Database and Cloud Storage for Firebase
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · User Account Registration and Authentication Google Sign-In
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Web and Mobile Analytics Google Analytics
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We also may need to share your personal information in the following
          situations:
          <br />· Business Transfers. We may share or transfer your information
          in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · When we use Google Maps Platform APIs. We may share your information
          with certain Google Maps Platform APIs (e.g., Google Maps API, Places
          API). We use certain Google Maps Platform APIs to retrieve certain
          information when you make location-specific requests. This includes:
          Location to show user's nearby food options; and other similar
          information. A full list of what we use information for can be found
          in this section and in the previous section titled "HOW DO WE PROCESS
          YOUR INFORMATION?" Google Maps uses GPS, Wi-Fi, and cell towers to
          estimate your location. GPS is accurate to about 20 meters, while
          Wi-Fi and cell towers help improve accuracy when GPS signals are weak,
          like indoors. This data helps Google Maps provide directions, but it
          is not always perfectly precise. We obtain and store on your device
          ("cache") your location. You may revoke your consent anytime by
          contacting us at the contact details provided at the end of this
          document. The Google Maps Platform APIs that we use store and access
          cookies and other information on your devices. If you are a user
          currently in the European Economic Area (EU countries, Iceland,
          Liechtenstein, and Norway) or the United Kingdom, please take a look
          at our Cookie Notice.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Affiliates. We may share your information with our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Notice. Affiliates include our parent company and any subsidiaries,
          joint venture partners, or other companies that we control or that are
          under common control with us.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Business Partners. We may share your information with our business
          partners to offer you certain products, services, or promotions.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Offer Wall. Our application(s) may display a third-party hosted
          "offer wall. " Such an offer wall allows third-party advertisers to
          offer virtual currency, gifts, or other items to users in return for
          the acceptance and completion of an advertisement offer. Such an offer
          wall may appear in our application(s) and be displayed to you based on
          certain data, such as your geographic area or demographic information.
          When you click on an offer wall, you will be brought to an external
          website belonging to other persons and will leave our application(s).
          A unique identifier, such as your user ID, will be shared with the
          offer wall provider in order to prevent fraud and properly credit your
          account with the relevant reward.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We are not responsible for the safety of any information
          that you share with third parties that we may link to or who advertise
          on our Services, but are not affiliated with, our Services.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          The Services, including our offer wall, may link to third-party
          websites, online services, or mobile applications and/or contain
          advertisements from third parties that are not affiliated with us and
          which may link to other websites, services, or applications.
          Accordingly, we do not make any guarantee regarding any such third
          parties, and we will not be liable for any loss or damage caused by
          the use of such third-party websites, services, or applications. The
          inclusion of a link towards a third party website, service, or
          application does not imply an endorsement by us. We cannot guarantee
          the safety and privacy of data you provide to any third-party
          websites. Any data collected by third parties is not covered by this
          Privacy Notice. We are not responsible for the content or privacy and
          security practices and policies of any third parties, including other
          websites, services, or applications that may be linked to or from the
          Services. You should review the policies of such third parties and
          contact them directly to respond to your questions.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          To the extent these online tracking technologies are deemed to be a
          "sale"/"sharing" (which includes targeted advertising, as defined
          under the applicable laws) under applicable US state laws, you can opt
          out of these online tracking technologies by submitting a request as
          described below under section "DO UNITED STATES RESIDENTS HAVE
          SPECIFIC PRIVACY RIGHTS?"
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Google Analytics
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may share your information with Google Analytics to track and
          analyze the use of the Services. The Google Analytics Advertising
          Features that we may use include: Google Analytics Demographics and
          Interests Reporting. To opt out of being tracked by Google Analytics
          across the Services, visit{" "}
          <ForageLinkText link={"https://tools.google.com/dlpage/gaoptout"}>
            Google Analytics Opt-out Browser Add-on Download Page
          </ForageLinkText>
          . You can opt out of Google Analytics Advertising Features through Ads
          Settings and Ad Settings for mobile apps. Other opt out means include{" "}
          <ForageLinkText link={"http://optout.networkadvertising.org/"}>
            http://optout.networkadvertising.org/
          </ForageLinkText>{" "}
          and{" "}
          <ForageLinkText
            link={"http://www.networkadvertising.org/mobile-choice"}
          >
            Mobile Opt Out - NAI: Network Advertising Initiative
          </ForageLinkText>
          . For more information on the privacy practices of Google, please
          visit the Google Privacy & Terms page.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We offer products, features, or tools powered by artificial
          intelligence, machine learning, or similar technologies.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          As part of our Services, we offer products, features, or tools powered
          by artificial intelligence, machine learning, or similar technologies
          (collectively, "AI Products"). These tools are designed to enhance
          your experience and provide you with innovative solutions. The terms
          in this Privacy Notice govern your use of the AI Products within our
          Services.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Use of AI Technologies
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We provide the AI Products through third-party service providers ("AI
          Service Providers"), including Google Cloud AI. As outlined in this
          Privacy Notice, your input, output, and personal information will be
          shared with and processed by these AI Service Providers to enable your
          use of our AI Products for purposes outlined in "WHEN AND WITH WHOM DO
          WE SHARE YOUR PERSONAL INFORMATION?" You must not use the AI Products
          in any way that violates the terms or policies of any AI Service
          Provider.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Our AI Products
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Our AI Products are designed for the following functions:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>· AI automation</Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Image analysis
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          How We Process Your Data Using AI
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          All personal information processed using our AI Products is handled in
          line with our Privacy Notice and our agreement with third parties.
          This ensures high security and safeguards your personal information
          throughout the process, giving you peace of mind about your data's
          safety.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about
          you.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or X
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform. If you log in using
          Facebook, we may also request access to other permissions related to
          your account, such as your friends, check-ins, and likes, and you may
          choose to grant or deny us access to each individual permission.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We will use the information we receive only for the purposes that are
          described in this Privacy Notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          8. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this Privacy Notice unless otherwise required
          by law.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this Privacy Notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
          twelve (12) months past the termination of the user's account.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          9. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cyber-criminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          10. DO WE COLLECT INFORMATION FROM MINORS?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at{" "}
          <ForageLinkText link={"mailto:support@forageapp.net"}>
            Support@forageapp.net
          </ForageLinkText>
          .
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          11. WHAT ARE YOUR PRIVACY RIGHTS?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: You may review, change, or terminate your account at any
          time, depending on your country, province, or state of residence.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          <Text style={{ textDecorationLine: "underline" }}>
            Withdrawing your consent:
          </Text>{" "}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "HOW CAN YOU CONTACT
          US ABOUT THIS NOTICE?" below.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          <Text style={{ textDecorationLine: "underline" }}>
            Opting out of marketing and promotional communications:
          </Text>{" "}
          You can unsubscribe from our marketing and promotional communications
          at any time by or by contacting us using the details provided in the
          section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will
          then be removed from the marketing lists. However, we may still
          communicate with you — for example, to send you service-related
          messages that are necessary for the administration and use of your
          account, to respond to service requests, or for other non-marketing
          purposes.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Account Information
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Contact us using the contact information provided.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          <Text style={{ textDecorationLine: "underline" }}>
            Cookies and similar technologies:
          </Text>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. You
          may also opt out of interest-based advertising by advertisers on our
          Services. If you have questions or comments about your privacy rights,
          you may email us at{" "}
          <ForageLinkText link={"mailto:support@forageapp.net"}>
            Support@forageapp.net
          </ForageLinkText>
          .
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          12. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this Privacy
          Notice.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          California law requires us to let you know how we respond to web
          browser DNT signals. Because there currently is not an industry or
          legal standard for recognizing or honoring DNT signals, we do not
          respond to them at this time.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: If you are a resident of California, Colorado, Connecticut,
          Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire,
          New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have
          the right to request access to and receive details about the personal
          information we maintain about you and how we have processed it,
          correct inaccuracies, get a copy of, or delete your personal
          information. You may also have the right to withdraw your consent to
          our processing of your personal information. These rights may be
          limited in some circumstances by applicable law. More information is
          provided below.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Categories of Personal Information We Collect
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Receiving help through our customer support channels;
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Participation in customer surveys or contests; and
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Category H - As long as the user has an account with us
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Sources of Personal Information
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Learn more about the sources of personal information we collect in
          "WHAT INFORMATION DO WE COLLECT?"
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          How We Use and Share Personal Information
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Learn about how we use your personal information in the section, "HOW
          DO WE PROCESS YOUR INFORMATION?"
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We collect and share your personal information through:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Targeting cookies/Marketing cookies
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Social media cookies
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Beacons/Pixels/Tags
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Click redirects
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Social media plugins. We use social media features, such as a "Like"
          button, and widgets, such as a "Share" button, in our Services. Such
          features may process your Internet Protocol (IP) address and track
          which page you are visiting on our website. We may place a cookie to
          enable the feature to work correctly. If you are logged in on a
          certain social media platform and you interact with a widget or button
          belonging to that social media platform, this information may be
          recorded to your profile of such social media platform. To avoid this,
          you should log out from that social media platform before accessing or
          using the Services. Social media features and widgets may be hosted by
          a third party or hosted directly on our Services. Your interactions
          with these features are governed by the privacy notices of the
          companies that provide them. By clicking on one of these buttons, you
          agree to the use of this plugin and consequently the transfer of
          personal information to the corresponding social media service. We
          have no control over the essence and extent of these transmitted data
          or their additional processing.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Will your information be shared with anyone else?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Learn more about how we disclose personal information to in the
          section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information. We have not sold or shared any personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months. We have disclosed the following
          categories of personal information to third parties for a business or
          commercial purpose in the preceding twelve (12) months:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          The categories of third parties to whom we disclosed personal
          information for a business or commercial purpose can be found under
          "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Your Rights
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          You have rights under certain US state data protection laws. However,
          these rights are not absolute, and in certain cases, we may decline
          your request as permitted by law.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          These rights include:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to know whether or not we are processing your personal data
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to access your personal data
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to correct inaccuracies in your personal data
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to request the deletion of your personal data
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to obtain a copy of the personal data you previously shared
          with us
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to non-discrimination for exercising your rights
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to opt out of the processing of your personal data if it is
          used for targeted advertising (or sharing as defined under
          California’s privacy law), the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects ("profiling")
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Depending upon the state where you live, you may also have the
          following rights:
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to obtain a list of the categories of third parties to which
          we have disclosed personal data (as permitted by applicable law,
          including California's and Delaware's privacy law)
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to obtain a list of specific third parties to which we have
          disclosed personal data (as permitted by applicable law, including
          Oregon’s privacy law)
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to limit use and disclosure of sensitive personal data (as
          permitted by applicable law, including California’s privacy law)
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          · Right to opt out of the collection of sensitive data and personal
          data collected through the operation of a voice or facial recognition
          feature (as permitted by applicable law, including Florida’s privacy
          law)
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          How to Exercise Your Rights
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          To exercise these rights, you can contact us by submitting a data
          subject access request, by emailing us at{" "}
          <ForageLinkText link={"mailto:support@forageapp.net"}>
            Support@forageapp.net
          </ForageLinkText>
          , or by referring to the contact details at the bottom of this
          document.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Under certain US state data protection laws, you can designate an
          authorized agent to make a request on your behalf. We may deny a
          request from an authorized agent that does not submit proof that they
          have been validly authorized to act on your behalf in accordance with
          applicable laws.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Request Verification
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. We will only use personal information provided in your
          request to verify your identity or authority to make the request.
          However, if we cannot verify your identity from the information
          already maintained by us, we may request that you provide additional
          information for the purposes of verifying your identity and for
          security or fraud-prevention purposes.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          If you submit the request through an authorized agent, we may need to
          collect additional information to verify your identity before
          processing your request and the agent will need to provide a written
          and signed permission from you to submit such request on your behalf.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          Appeals
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Under certain US state data protection laws, if we decline to take
          action regarding your request, you may appeal our decision by emailing
          us at{" "}
          <ForageLinkText link={"mailto:support@forageapp.net"}>
            Support@forageapp.net
          </ForageLinkText>
          . We will inform you in writing of any action taken or not taken in
          response to the appeal, including a written explanation of the reasons
          for the decisions. If your appeal is denied, you may submit a
          complaint to your state attorney general.
        </Text>
        <Text
          style={[styles.foragePrivacyPolicyParagraph, { fontWeight: "bold" }]}
        >
          California "Shine The Light" Law
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          14. DO WE MAKE UPDATES TO THIS NOTICE?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          We may update this Privacy Notice from time to time. The updated
          version will be indicated by an updated "Revised" date at the top of
          this Privacy Notice. If we make material changes to this Privacy
          Notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this Privacy Notice frequently to be informed of how we
          are protecting your information.
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          If you have questions or comments about this notice, you may email us
          at{" "}
          <ForageLinkText link={"mailto:support@forageapp.net"}>
            Support@forageapp.net
          </ForageLinkText>
        </Text>
        {/* ============================================================ */}
        <br />{" "}
        <Text style={styles.foragePrivacyPolicyHeader1}>
          16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Text>
        <Text style={styles.foragePrivacyPolicyParagraph}>
          Based on the applicable laws of your country or state of residence in
          the US, you may have the right to request access to the personal
          information we collect from you, details about how we have processed
          it, correct inaccuracies, or delete your personal information. You may
          also have the right to withdraw your consent to our processing of your
          personal information. These rights may be limited in some
          circumstances by applicable law. To request to review, update, or
          delete your personal information, please fill out and submit a data
          subject access request.
        </Text>
        {/* ============================================================ */}
      </ScrollView>
    );
  }
}
