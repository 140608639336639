import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { Platform } from "react-native";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  deleteUser,
} from "firebase/auth";

import { FORAGE_ENV } from "@env";

import { apiKey } from "@env";
import { authDomain } from "@env";
import { databaseURL } from "@env";
import { projectId } from "@env";
import { storageBucket } from "@env";
import { messagingSenderId } from "@env";
import { appId } from "@env";
import { measurementId } from "@env";

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  databaseURL: databaseURL,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};

import userDefaultSettings from "./userDefaultSettings";
import databaseNames from "./databaseNames";

class Fire {
  constructor() {
    // Initialize Firebase
    var app = undefined;
    try {
      app = firebase.app("ForageApp");
    } catch (e) {
      // Forage App is uninitialized
      app = firebase.initializeApp(firebaseConfig, "ForageApp");
    }

    this.firebaseConfig = firebaseConfig;
    this.app = app;
    this.platform = Platform.OS;

    this.auth = firebase.auth(this.app);
    // this.auth = getAuth(firebaseConfig);
    // this.database = firebase.database( this.app );
    // this.auth = getAuth(firebaseConfig);

    // Method to select firestore DB for test/prod
    // source: https://stackoverflow.com/questions/77061688/how-can-i-connect-to-a-non-default-firestore-database-using-node-using-multiple
    this.firestoreDB =
      FORAGE_ENV == "prod"
        ? getFirestore(this.app, databaseNames.prod)
        : getFirestore(this.app, databaseNames.test);
    this.auth.languageCode = this.auth.useDeviceLanguage();
    this.provider = new GoogleAuthProvider();
    // this.provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    this.functions = firebase.functions(this.app);

    console.log("FIREBASE WAS CREATED w/ FORAGE_ENV:  " + FORAGE_ENV);
    this.FORAGE_ENV = FORAGE_ENV;
    if (FORAGE_ENV == "test") {
      this.FORAGE_ENV = "test";
    } else if (FORAGE_ENV == "prod") {
      this.FORAGE_ENV = "production";
    }
  }

  tester = async () => {
    let tester = "tester-";
    console.log(tester + this.FORAGE_ENV);
    let testerFn = this.functions.httpsCallable("tester-" + this.FORAGE_ENV);

    testerFn()
      .then((response) => {
        console.log(response);
        // console.log( response.data );
      })
      .catch((err) => {
        console.log("Error @ tester: " + err);
      });
  };

  // Restaurant methods.

  curator = async (service) => {
    let curator = "promos-curator-" + this.FORAGE_ENV;
    console.log(curator);
    let curatorFn = this.functions.httpsCallable(curator);

    return new Promise((resolve) => {
      curatorFn({ service: service })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log("Error @ curator: " + err);
          resolve();
        });
    });
  };

  restaurant_search = async (
    lat,
    long,
    rad,
    page_token = undefined,
    query = undefined
  ) => {
    /* 
        (NOTE): Restaurant Search Back End API
            Required:
                latitude: location lat
                longitude: location long
                radius: location radius (meters)
            Optional:
                page_token: page token to get the next page
                query: string query to search with
        */

    let restaurant = "restaurant-search-" + this.FORAGE_ENV;
    console.log(restaurant);
    let restaurantFn = this.functions.httpsCallable(restaurant);

    let data = { latitude: lat, longitude: long, radius: rad };
    if (page_token) {
      data.page_token = page_token;
    }
    if (query) {
      data.query = query;
    }
    console.log(data);

    return new Promise((resolve) => {
      if (lat == undefined || long == undefined) {
        resolve({ results: [], next_page_token: "" });
      } else {
        restaurantFn(data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            console.log("Error @ restaurant_search: " + err);
            resolve({ results: [], next_page_token: "" });
          });
      }
    });
  };

  restaurant_delivery_provider_search = async (
    place_id,
    uch = false,
    usrp = true,
    casf = true
  ) => {
    let delivery = "restaurant-delivery-provider-search-";
    // console.log( delivery + this.FORAGE_ENV + " @ place_id: " + place_id );
    let deliveryFn = this.functions.httpsCallable(delivery + this.FORAGE_ENV);
    // let data = {place_id: place_id, uch: uch, usrp: usrp};
    let data = { place_id: place_id };
    return new Promise((resolve) => {
      deliveryFn(data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log("Error @ delivery_provider_search: " + err);
          resolve({ services: [] });
        });
    });
  };

  restaurant_delivery_fees = async (
    lat,
    long,
    rad,
    page_token = undefined,
    query = undefined
  ) => {
    /*
     */
    let fees_fn_name = "restaurant-delivery-fees-" + this.FORAGE_ENV;
    let feesFn = this.functions.httpsCallable(fees_fn_name);
    console.log(fees_fn_name);

    let data = { latitude: lat, longitude: long, radius: rad, wfs: true };
    if (page_token) {
      data.token = page_token;
    }
    // if(query){ data.query = query }

    return new Promise((resolve) => {
      feesFn(data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log("Error @ restaurant_delivery_fees: " + err);
          resolve({ results: [] });
        });
    });
  };

  address_validation = async (
    addressLines,
    regionCode = "US",
    locality = ""
  ) => {
    /*
        Sorces:
            - https://developers.google.com/maps/documentation/address-validation
        */
    console.log("address_validation");
    let URL =
      "https://addressvalidation.googleapis.com/v1:validateAddress?key=" +
      firebaseConfig.apiKey;

    var data = {
      address: {
        regionCode: regionCode,
        locality: locality,
        addressLines: [addressLines],
      },
    };
    var form = JSON.stringify(data);

    return new Promise((resolve) => {
      fetch(URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: form,
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response.result);
          resolve(response.result);
        })
        .catch((error) => {
          console.error(error);
          resolve({});
        });
    });
  };

  // User account methods.

  google_signin_with_popup = async () => {
    console.log("google_signin_with_popup() pressed...");
    return new Promise((resolve) => {
      if (this.auth.currentUser != null) {
        console.log("google_signin_with_popup() user is already logged in");
        resolve();
      } else {
        signInWithPopup(this.auth, this.provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // IdP data available using getAdditionalUserInfo(result)
            // ...
            console.log(
              "google_signin_with_popup() finished with signInWithPopup()..."
            );
            resolve();
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.log(
              "google_signin_with_popup() finished with error code(" +
                errorCode +
                ") " +
                "message(" +
                errorMessage +
                ") credential(" +
                credential +
                ")"
            );
            resolve();
          });
      }
    });
  };

  signout = async () => {
    const fn_name = "signout-" + this.FORAGE_ENV;
    console.log(fn_name);
    await this.auth.signOut();
  };

  isNewUser = async () => {
    // Check if the user is logged in. Else check the time of last login
    return new Promise((resolve) => {
      if (this.auth.currentUser) {
        const creationTime = new Date(
          this.auth.currentUser.metadata.creationTime
        );
        const creationEpoch = creationTime.getTime();

        const signInTime = new Date(
          this.auth.currentUser.metadata.lastSignInTime
        );
        const signInEpoch = signInTime.getTime();
        const isNewUser = creationEpoch == signInEpoch;
        console.log("isNewUser: " + isNewUser);
        resolve(isNewUser);
      } else {
        resolve(false);
      }
    });
  };

  setUserSettings = async (newSettings) => {
    // Set default settings for users.
    // Will set user's settings to default if no new settings are provided.
    // Return: True if user's setup was executed.
    // Return: False if user's setup was NOT executed.

    // Rules for database to edit user's settings.
    // https://firebase.google.com/docs/firestore/security/rules-conditions

    const fn_name = "setDefaultUserSettings-" + this.FORAGE_ENV;
    console.log(fn_name);

    return new Promise((resolve, reject) => {
      if (!this.auth.currentUser) {
        resolve(false);
      } else {
        const uid = this.auth.currentUser.uid;

        // Setup user's setting options.
        // https://firebase.google.com/docs/firestore/manage-data/add-data#web_2
        setDoc(
          doc(
            this.firestoreDB,
            databaseNames.collections.userSettings,
            "" + uid
          ),
          newSettings ? newSettings : userDefaultSettings
        )
          .then(() => {
            console.log("User settings set to default.");
            resolve(true);
          })
          .catch((err) => {
            console.log("Error @ " + fn_name + " : " + err);
            reject(false);
          });
      }
    });
  };

  getUserSettings = async () => {
    // Retrieve the current user's settings.
    // Return user settings data if successful.
    // Return empty dict if unsuccessful.
    const fn_name = "getUserSettings-" + this.FORAGE_ENV;
    console.log(fn_name);
    const uid = this.auth.currentUser.uid;

    const docSnap = await getDoc(
      doc(this.firestoreDB, databaseNames.collections.userSettings, "" + uid)
    );
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      return {};
    }
  };

  setupNewUser = async () => {
    const fn_name = "setupNewUser-" + this.FORAGE_ENV;
    console.log(fn_name);
    await this.setUserSettings();
  };

  userDeleteAccount = async () => {
    // Delete user.
    // Return: True if successful.
    // Return: False if not successful.

    const deliveryFn = "userDeleteAccount-" + this.FORAGE_ENV;
    const uid = this.auth.currentUser.uid;
    // Source: https://firebase.google.com/docs/auth/web/manage-users#delete_a_user
    // NOTE: must delete user data before deleting user account based on database rules
    await deleteDoc(
      doc(this.firestoreDB, databaseNames.collections.userSettings, "" + uid)
    );
    await deleteUser(this.auth.currentUser);
  };

  // Miscellaneous.

  getWebsitePwd = async () => {
    let fn_name = "getWebsitePwd-" + this.FORAGE_ENV;
    console.log(fn_name);
    const docRef = doc(
      this.firestoreDB,
      databaseNames.collections.websitePwd,
      "pwd"
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const retPwd = docSnap.data().pwd;
      return retPwd;
    } else {
      console.log("website password does not exist.");
      return "";
    }
  };
}

Fire.shared = new Fire();
export default Fire;
