/*
User's initial default settings

Each setting fields:

Name     : String of the setting names
value    : Actual value of the setting
icon     : React Native Materials icon
isBeta   : Warning if feature or setting is still in beta
*/

const userDefaultSettings = {
  parse_emails: {
    name: "Parse Emails",
    value: false,
    icon: "email-outline",
    isBeta: true,
  },
  mark_emails_open: {
    name: "Mark emails as opened",
    value: false,
    icon: "email-open-outline",
    isBeta: true,
  },
  share_promos: {
    name: "Share promos with other users",
    value: false,
    icon: "account-group-outline",
    isBeta: true,
  },
};

export default userDefaultSettings;
